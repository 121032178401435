.site-title {
    font-size: 2.2rem; 
    font-weight: bold;  
    font-family: Verdana, sans-serif;
}

.header {
    background-color: $white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    .title {
        background-color: #E8ECF1;
        background-image: url("../img/color-line.png");
        background-repeat: repeat-x;
        background-position: bottom;
        .logo {
            margin: $global-margin;
        }
    }
    .main-menu {
        ul li:last-child a.level-one .icon-box {
            border-right: 1px dashed $medium-gray;
        }
        a.level-one {
            padding-top: $global-padding;
            padding-bottom: $global-padding;
            .icon-box {
                height: 48px;
                max-width:  100%;
                display:  flex;
                align-items: center;
                justify-content:  center;
                margin-bottom:$global-margin;
                border-left: 1px dashed $medium-gray;
            }
            .icon {
                max-width: 48px; 
                max-height: 48px;
                
                display:block;
                font-size: $global-font-size *2.5;
                color: $primary-color;
                
            }
       
            padding-left: 0;
            padding-right: 0;
            
        }
        .icon {
            max-width: 16px; 
        }
        .dropdown.menu > li.is-dropdown-submenu-parent > a {
            padding-right:0;
        }
    
        .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
            display: none;
        }
    }
}


.carousel {
    background-image: url('../img/bg_carousel.png');
    padding: $global-padding 0;
}

.info-bar {
    background-color: $light-gray;
    padding: $global-padding/2 0;
}

.index-title {
    font-size: $global-font-size*1.5; 
    border-bottom: 4px solid $primary-color;
    padding: $global-padding/2 0;
    margin-bottom: $global-margin;
}

//首頁主區域
.info-area {
    min-height: 50vh;
    background-image: url("../img/bg-city.png");
    background-repeat: repeat-x;
    background-position: bottom;
    
}
.news {
    border-bottom: 1px solid $medium-gray;
    li {
        border-bottom: 1px solid $light-gray;
        padding: $global-padding/1.5 0;
    }
    strong {
        display: inline-block;
        margin-right: $global-margin;
        padding-right: $global-padding;
        border-right: 1px dashed $medium-gray;
    }
}
.search-box {
    border: 4px solid $primary-color;
    padding: $global-padding;
    background-color: $white;
    border-radius: $global-radius;
}

.footer {
    background-color: $primary-color;
    padding: $global-padding*2 0;
    color: $white;
}

.quick-link {
    .button {
        font-size: $global-font-size*1.1;
        font-weight: bold;
        border-radius: $global-radius;
        position: relative;
        .redTag {
            width: 1.5rem;
            height: 1.5rem;
            background-color: #cc4b37;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            border-top-left-radius:4px;
            .testt {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 1.5rem 1.5rem;
                border-color: transparent transparent #ffffff transparent;
                position: absolute;
                right: 0px;
            }
        }
    }
}

.main-map-link {
    margin-top: -40px;
    background-image: url("../img/color-line.png");
    background-repeat: repeat-x;
    background-position: bottom;
    padding-bottom: 4px;
    .button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        padding-bottom: $global-padding/2;
    }
}
.main-map {
    
    padding: $global-padding*2;
    .main-menu {
        .level-one {
            padding-left: 0;
            padding-right: 0;
            font-weight: bold;
            img {
                vertical-align: -2px; 
            }
            .icon {
                margin:0 auto !important;
                max-width: 16px; 
                font-size: $global-font-size;
                
            }
            a {
                padding-left: 0;
                padding-right: 0;
                font-size: $global-font-size*.9;
            }
        }
        a  {
            padding-top: $global-padding/2;
            padding-bottom: $global-padding/2;
        }
        
        .dropdown.menu > li.is-dropdown-submenu-parent > a {
            padding-right:0;
        }
        .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
            display: none;
        }
    }
}

.articles {
    .table-header {
        div{ 
            border-right: 1px solid $light-gray;
        }
    }
    li>div {
        padding:0.8rem 1rem;
    }
    .article {
        background-color: $white;
        .category{
            font-size: $global-font-size*0.9;
        }
        .time {
            font-size: $global-font-size*0.9;
        }
        div {
            border-right: 1px solid $light-gray;
        }
    }
    .article:nth-child(even){
        background-color: #E8ECF1;
    }
}

// admin
.admin-top-bar {
    border-bottom: 1px solid $medium-gray;
}
.admin-menu {
    background-color: $light-gray; 
    min-height: 100vh;
    border-right: 1px solid $medium-gray;
    height: 100%;
}

.footer-menu {
   
    a {
        display:inline-block; 
        padding-right:1rem;
    }

    .second-menu {
        display: inline-flex;
        a {
            padding-left: 0;
            padding-right: 0;
        }
        a::before {
            content:'・';
        }
    }
    .third-menu {
        display: inline-flex;
        a {
            padding-left: 0;
            padding-right: 0;
        }
        a::before {
            content:'・';
        }
    }
}

@media screen and (max-width: 39.9375em) {
    
    .site-title {
        font-size: 1.3rem; 
    }
    .header {
        .title {
            .logo {
                margin: $global-margin/2 $global-margin $global-margin/2 $global-margin/2;
                height: 48px;;
            }
        }
        .main-menu {
            ul li:last-child a.level-one .icon-box {
                border-right: none;
            }
            a.level-one {
  
                .icon-box {
                    height: 48px;
                    max-width:  100%;
                    display:  flex;
                    align-items: center;
                    justify-content:  center;
                    margin-bottom:0;
                    border-left: none;
                }
                .icon {
                    max-width: 100%; 
                    max-height: auto;
                    
                    display:block;
                    font-size: $global-font-size *2.5;
                    color: $primary-color;
                    
                }
           
                padding-left: 0;
                padding-right: 0;
                
            }
            .icon {
                max-width: 16px; 
            }
            .dropdown.menu > li.is-dropdown-submenu-parent > a {
                padding-right:0;
            }
        
            .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
                display: none;
            }
        }
    }

    .searchBar {
        margin-bottom: $global-margin;
    }

    .articles{
        li>div {
            padding:0;
        }
        .article {
            background-color: $white;
            padding: $global-padding/3;
            .category{
                color: $dark-gray;
                &::after {
                    content:'・';
                }
            }
            .time{
                color: $dark-gray;
            }
            div {
                border-right: none;
            }
            .download {
                padding-right: 1ren !important;
            }
        }
    }

    .info-area {
        
    }
    .news {
        strong {
            display: block;
            margin-right: 0;
            padding-right: 0;
            color: $dark-gray;
            border-right: none;
        }
    }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .site-title {
        font-size: 1.5rem; 
    }
}
